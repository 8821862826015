import React from "react"
import RecentEvents from "../../components/shortcodes/recent-events"
import RecentPosts from "../../components/shortcodes/recent-posts"

const SidebarEvents = ({ exclude }) => {
  return (
    <div className="post-sidebar">
      <div className="sidebar">
        <div className="sidebar-title">Recent Events</div>
        <div className="sidebar-content">
          <RecentEvents style="posts-sidebar" limit={4} exclude={exclude} />
        </div>
      </div>
      <div className="sidebar margin-top">
        <div className="sidebar-title">Recent Posts</div>
        <div className="sidebar-content">
          <RecentPosts style="posts-sidebar" limit={4} exclude={exclude} />
        </div>
      </div>
    </div>
  )
}

export default SidebarEvents
