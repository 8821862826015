import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import RecentTemplate from "../recent-template"

const RecentEvents = ({ style, limit, exclude }) => {
  const allEvents = useStaticQuery(graphql`
    query {
      allWpEvent(sort: { fields: date, order: DESC }) {
        nodes {
          uri
          title
          date(formatString: "DD/MM/YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!allEvents?.allWpEvent?.nodes) return null

  return (
    <RecentTemplate
      data={allEvents.allWpEvent.nodes}
      style={style || "posts-grid"}
      limit={limit || 9}
      exclude={exclude || 0}
    />
  )
}

export default RecentEvents
