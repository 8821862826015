import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import DateIcon from "../images/time.svg"
import NameIcon from "../images/info.svg"
import BoothIcon from "../images/megaphone.svg"
import VenueIcon from "../images/location.svg"
import LinkIcon from "../images/link.svg"
import SidebarEvents from "./partials/sidebar-events"
import Seo from "../components/seo"

const Event = ({ data }) => {
  const event = data.wpEvent
  const lang = data.acfSeo?.lang
  const seo = event.seo

  return (
    <Layout className="post-template container__tablet">
      <Seo
        title={seo.title} 
        description={seo.metaDesc}
        language={lang}
        canonical={seo.canonical}
        ogTitle={seo.opengraphTitle}
        ogDescription={seo.opengraphDescription}
        ogImage={seo.opengraphImage?.localFile?.childImageSharp?.fixed?.src}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage?.localFile?.childImageSharp?.fixed?.src}
      />
      <div className="post-main">
        <Img
          fluid={event?.featuredImage?.node?.localFile?.childImageSharp?.fluid}
        />
        <div className="content">
          <h1>{event?.title}</h1>

          <div className="event-items">
            {event?.acfEvent?.exhibitionName && (
              <div className="event-item">
                <div className="event-icon">
                  <NameIcon />
                </div>
                <div clasName="event-desc">
                  <p className="event-title">Name</p>
                  <p className="event-caption">
                    {event.acfEvent.exhibitionName}
                  </p>
                </div>
              </div>
            )}

            {event?.acfEvent?.dateStart && event?.acfEvent?.dateEnd && (
              <div className="event-item">
                <div className="event-icon">
                  <DateIcon />
                </div>
                <div clasName="event-desc">
                  <p className="event-title">Date</p>
                  <p className="event-caption">
                    {event.acfEvent.dateStart} - {event.acfEvent.dateEnd}
                  </p>
                </div>
              </div>
            )}

            {event?.acfEvent?.boothNo && (
              <div className="event-item">
                <div className="event-icon">
                  <BoothIcon />
                </div>
                <div clasName="event-desc">
                  <p className="event-title">Booth No.</p>
                  <p className="event-caption">{event.acfEvent.boothNo}</p>
                </div>
              </div>
            )}

            {event?.acfEvent?.venue && (
              <div className="event-item">
                <div className="event-icon">
                  <VenueIcon />
                </div>
                <div clasName="event-desc">
                  <p className="event-title">Venue</p>
                  <p className="event-caption">{event.acfEvent.venue}</p>
                </div>
              </div>
            )}

            {event?.acfEvent?.website && (
              <div className="event-item">
                <div className="event-icon">
                  <LinkIcon />
                </div>
                <div clasName="event-desc">
                  <p className="event-title">Website</p>
                  <p className="event-caption">{event.acfEvent.website}</p>
                </div>
              </div>
            )}
          </div>

          <div dangerouslySetInnerHTML={{ __html: event?.content }}></div>
        </div>
      </div>
      <SidebarEvents exclude={event?.databaseId} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpEvent(id: { eq: $id }) {
      title
      databaseId
      content
      featuredImage {
        node {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      acfSeo {
        language
      }
      seo {
        title
        canonical
        metaDesc
        breadcrumbs {
          url
          text
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            childImageSharp {
              fixed(width: 800, height: 418) {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
      acfEvent {
        dateStart
        dateEnd
        exhibitionName
        boothNo
        venue
        website
      }
    }
  }
`

export default Event
