import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import RecentTemplate from "../recent-template"

const RecentPosts = ({ style, limit, exclude }) => {
  const allPosts = useStaticQuery(graphql`
    query {
      allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          databaseId
          uri
          title
          date(formatString: "DD/MM/YYYY")
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!allPosts?.allWpPost?.nodes) return null

  return (
    <RecentTemplate
      data={allPosts.allWpPost.nodes}
      style={style || "posts-grid"}
      limit={limit || 9}
      exclude={exclude || 0}
    />
  )
}

export default RecentPosts
