import React from "react"
import Img from "gatsby-image"
import Link from "gatsby-link"

const RecentTemplate = ({ data, style, limit, exclude }) => {
  return (
    <div className={style}>
      {data.map((post, index) => {
        if (post.databaseId === exclude || (index > 1 && index + 1 > limit))
          return null
        return (
          <Link to={post.uri} title={post.title}>
            <div className="post">
              <div className="post-image">
                <Img
                  fluid={
                    post.featuredImage?.node?.localFile?.childImageSharp?.fluid
                  }
                  alt={post.title}
                />
              </div>
              <div className="post-content">
                <p className="post-title">{post.title}</p>
                <p className="post-date">{post.date}</p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default RecentTemplate
